import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonMethodsService {

  constructor() {}
  isEmptyObj(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }
}
